
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';
import { Component, Vue } from 'vue-property-decorator';

import PRICE_TYPE from '@/modules/document-filters/constants/price-type.constant';
import LosFilter from '@/modules/cluster/components/_filters/los.vue';
import ChainFilters from '@/modules/chain/components/chain-filters.vue';
import TogglePrices from '@/modules/document-filters/components/toggle-prices.vue';
import CustomSelect from '@/modules/common/components/ui-kit/custom-select.vue';
import RoomTypesService, { RoomTypesServiceS } from '@/modules/room-types/room-types.service';
import MealTypesService, { MealTypesServiceS } from '@/modules/meal-types/meal-types.service';
import type ClusterService from '../../cluster.service';

@Component({
    components: {
        LosFilter,
        ChainFilters,
        TogglePrices,
        CustomSelect,
    },
})
export default class RatesClusterActions extends Vue {
    @inject(KEY.ClusterService) private clusterService!: ClusterService;
    @inject(RoomTypesServiceS) private roomTypesService!: RoomTypesService;
    @inject(MealTypesServiceS) private mealTypesService!: MealTypesService;

    get currentPath() {
        return this.$route.path;
    }

    get currentRouteName() {
        return this.$route.name!;
    }

    get roomType() {
        return this.clusterService.ratesSettings.roomTypeId;
    }

    set roomType(value: number) {
        this.clusterService.ratesSettings.roomTypeId = value;
    }

    get roomTypeItems() {
        return this.roomTypesService.roomsWithAny
            .map(room => ({
                value: room.id,
                name: this.$te(room.displayName) ? this.$tc(room.displayName) : room.displayName,
            }));
    }

    get mealType() {
        return this.clusterService.ratesSettings.mealTypeId;
    }

    set mealType(value: number | null) {
        this.clusterService.ratesSettings.mealTypeId = value;
    }

    get mealTypeItems() {
        return this.mealTypesService.mealTypes
            .map(meal => ({
                value: meal.id,
                name: this.$tc(meal.displayName),
            }));
    }

    get priceType() {
        return this.clusterService.ratesSettings.priceType;
    }

    set priceType(value: PRICE_TYPE | null) {
        this.clusterService.ratesSettings.priceType = value;
    }

    get priceTypeItems() {
        return Object
            .values(PRICE_TYPE)
            .map(priceType => ({
                value: priceType,
                name: this.$tc(`price.${priceType}`),
            }));
    }

    get isChainLevel() {
        return this.$route.path.split('/')[1] === 'chain';
    }

    get baseBranchRoute() {
        const currentBranch = this.$route.name!.split('.')[0];

        switch (currentBranch) {
            case 'chain':
                return 'chain.rates';

            case 'cluster':
                return 'cluster.rates';

            default: break;
        }

        return 'rates';
    }

    get ratesTableRoute() {
        return `${this.baseBranchRoute}.table`;
    }
}
